import React from "react";
import "./src/styles/global.css";
import { ActionContextProvider, createConfig } from "./src/cra";

export const wrapRootElement = ({ element }) => {
  createConfig({
    website: "Mutual of Omaha",
  });
  return <ActionContextProvider>{element}</ActionContextProvider>;
};
